import React, {useContext, useRef, useState} from 'react';
import {Button, Container, Icon, Input, Segment} from "semantic-ui-react";
import './DemosSidebar.css'
import ConnectionsTree from "../ConnectionsTree/ConnectionsTree";
import TicketsSegment from "../TicketsSegment/TicketsSegment";
import DemosSegment from "../DemosSegment/DemosSegment"
import {AppContext} from "../../../Context/AppContext";

function DemosSidebar(props) {
    const [appState,] = useContext(AppContext);
    const [treeNodeFilterString, setTreeNodeFilterString] = useState("");
    const [treeDraggable, setTreeDragable] = useState(false);
    const searchInputRef = useRef();

    return (
        <Container className='sidebarContainer'>
            <Segment className='searchInputSegment' raised color='blue'>
                <Input size='small' fluid icon placeholder='Search...'
                       value={treeNodeFilterString}
                       onChange={(event) => {
                           setTreeNodeFilterString(event.target.value)
                       }}
                       onKeyDown={(event) => {
                           // Handle Esc button to clear
                           let code = event.charCode || event.keyCode;
                           code === 27 && setTreeNodeFilterString("")
                       }}
                       ref={searchInputRef}>
                    <input/>
                    <Icon name='times circle outline' size='large' link
                          onClick={() => setTreeNodeFilterString("")}/>
                </Input>
            </Segment>
            <Segment raised color='blue' className='demoList'>
                <Button icon='refresh'
                        color='grey'
                        basic inverted
                        size='small'
                        title='Reload'
                        className='topButtonRight'
                        loading={appState.demosLoading || appState.ticketsLoading}
                        onClick={() => {
                            appState.actions.updateDemos();
                            appState.actions.updateTickets();
                        }
                        }
                />
                <Button icon='expand arrows alternate'
                        color='grey'
                        basic inverted
                        size='small'
                        title='Reload'
                        className='topButtonRight'
                        onClick={() => {
                            appState.actions.resizeAllTabs();
                        }
                        }
                />
                <DemosSegment
                    searchString={treeNodeFilterString}
                />
            </Segment>
            <Segment raised color='blue' className='ticketList'>
                <TicketsSegment
                    searchString={treeNodeFilterString}
                />
            </Segment>

        </Container>
    );
}

export default DemosSidebar;
