import React, {useContext} from "react";
import {AppContext} from "../../../Context/AppContext";



const DemoItem = ({
						demo
					}) => {
	const [appState,] = useContext(AppContext);
	

	return (
		<div index={demo.id} className="demoItem">
				<span
					onDoubleClick={() => appState.actions.activateDemo(demo.connections, appState.rawConnections ,appState.user, demo.id)}>
					<i class ="blue computer small icon" name='computer' size="small" color="blue"/>
					{demo.name}
				</span>
		</div>
	)
};

export default DemoItem;
