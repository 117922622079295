import React, {useContext, useState} from 'react';
import {AppContext} from "../../../Context/AppContext";
import {Accordion, SegmentGroup} from "semantic-ui-react";
import DemoItem from "./DemoItem"

function DemosSegment({searchString}) {
    const [appState,] = useContext(AppContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const myDemos = searchString ?
        appState.demos.filter(demo =>
            demo.name
            .toLowerCase()
            .includes(searchString.toLowerCase()))
        : appState.demos;


    return (
        <React.Fragment>
            {myDemos.length > 0 ?
                <SegmentGroup className='demosList'>
                    <Accordion>
                        {myDemos.length > 0 &&
                        <div className="demosSection" id="DemosTree">
                            <span className="demosSectionHeader">Avaliable Demos</span>
                            {myDemos.map(demo =>
                                <DemoItem key={demo.id}
                                               demo={demo}/>)}
                        </div>}
                    </Accordion>
                </SegmentGroup>
                :
                <div style={{width: "100%", textAlign: "center"}}>
                    {appState.demos.length > 0 ? "no results" : "No Demos"}
                </div>
            }
        </React.Fragment>
    );
}

export default DemosSegment;
