export const defaultLayout = {
    global: {
        "splitterSize": 5,
        "tabEnableRename": false,
        "tabSetHeaderHeight": 25,
        "tabSetTabStripHeight": 25

    },
    layout: {
        "type": "row",
        "children": [
            {
                "type": "row",
                "weight": 25,
                "children": [
                    {
                        "type": "tabset",
                        "id": "tabset_main",
                        "name": "SCADA Systems",
                        "weight": 50,
                        "children": [
                            {
                                "type": "tab",
                                "id": "welcome_tab",
                                "name": "1",
                                "component": "welcomeScreen",
				"enableClose": false,
				"enableDrag": false
                            }
                        ]
                    },
                    {
                        "type": "tabset",
                        "id": "tabset_hmi",
                        "name": "HMI/Kali",
                        "weight": 50,
                        "children": [
                            {
                                "type": "tab",
                                "name": "3",
				"enableClose": false,
				"enableDrag": false
                            }
                        ]
                    }
                ]
            },
            {
                "type": "row",
                "weight": 25,
                "children": [
                    {
                        "type": "tabset",
                        "name": "CCTV",
                        "id": "tabset_camera",
                        "weight": 50,
                        "children": [
                            {
                                "type": "tab",
                                "name": "2",
				"enableClose": false,
				"enableDrag": false
                            }
                        ]
                    },
                    {
                        "type": "tabset",
                        "name": "Security Appliances",
                        "id": "tabset_probe",
                        "weight": 50,
                        "children": [
                            {
                                "type": "tab",
                                "name": "4",
				"enableClose": false,
				"enableDrag": false
                            }
                        ],
                        "active": true
                    }
                ]
            }
        ]
    },
    "borders": [
        {
            "type": "border",
            "selected": 0,
            "location": "left",
            "show": "true",
            "enableDrop": "false",
            "size": 250,
            "children": [
                {
                    "type": "tab",
                    "name": "Connections",
                    "id": "border_left_connections",
                    "enableDrag": false,
                    "enableClose": false,
                    "component": "ConnectionSidebar"
                },
                {
                    "type": "tab",
                    "name": "Demo Layouts",
                    "id": "border_left_demos",
                    "enableDrag": false,
                    "enableClose": false,
                    "component": "DemosSidebar"
                },
                {
                    "type": "tab",
                    "name": "Settings",
                    "id": "border_left_settings",
                    "enableDrag": false,
                    "enableClose": false,
                    "component": "SettingsSidebar"
                }

            ]
        }
    ]

}
    ;
